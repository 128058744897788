import React from "react";
import { NavLink } from "react-router-dom";
import "./Footer.css";
import ebu from "../../assets/ebu.png";
import CookieConsent, { OPTIONS } from "react-cookie-consent";
const Footer = () => {
  return (
    <div>
      <div className="Footer">
        <div>
          <CookieConsent
            disableStyles={true}
            location={OPTIONS.BOTTOM}
            buttonClasses="btn btn-primary"
            containerClasses="alert alert-warning col-lg-12"
            contentClasses="text-capitalize"
          >
            Wir verwenden Cookies.
          </CookieConsent>
        </div>
        <div>
          <p>© 2022 Praxis Urologie und Kinderurologie Nasser AlMuliki</p>
          <p>
            <NavLink to="/impressum">Impressum</NavLink> |
            <NavLink to="/datenschutz">Datenschutz</NavLink>
          </p>
          <p>
            <a href="https://www.ebu.com/" target="_blank" rel="noreferrer">
              <img className="ebu-image" src={ebu} alt="EBU" />
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
