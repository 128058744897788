import React, { StrictMode } from "react";
import Footer from "../footer/Footer";
import "./Pages.css";
import ContactForm from "./ContactForm";
import Nasser from "./Nasser";
import Herzlich from "./Herzlich";
import Anzeige from "./Anzeige";
import TerminHome from "./TerminHome";
import TerminSticky from "./Termin-Sticky";

import Map from "./Map";
const Home = () => {
  return (
    <StrictMode>
      <div className="main">
        <Herzlich />
        <Anzeige />
        <TerminHome />
        <ContactForm />
        <Nasser />
        <Map />
        <TerminSticky />
        <Footer />
      </div>
    </StrictMode>
  );
};

export default Home;
