import React from "react";
import Footer from "../footer/Footer";
const Impressum = () => {
  return (
    <div>
      <div className="kontakt">
        <p style={{ color: "#00387b", fontSize: 50, fontWeight: "bold" }}>
          Sprechstundenzeiten:
        </p>
        <pre />
        <p style={{ fontSize: 30 }}>
          Montag,Dienstag und Donnerstag: <pre />
          <font color="#00387b">Von 8-12 Uhr und 14-17 Uhr</font> <pre />
          Mittwoch (Op-Tag): <font color="#00387b">geschlossen</font> <pre />
          Freitag: <font color="#00387b">Von 8-12 Uhr</font> <pre />
          Notfall-Sprechstunde: <pre />
          <font color="#00387b">Jeden Tag von 8-9 Uhr</font>
        </p>
        <pre />
        <p style={{ color: "#00387b", fontSize: 50, fontWeight: "bold" }}>
          IMPRESSUM
        </p>
        <pre />
        <p style={{ color: "#00387b", fontSize: 20 }}>
          <strong>Angaben nach § 5 TMG / § 55 RStV.: </strong>
          <pre />
          Praxis für Urologie und Kinderurologie
          <pre /> Nasser AlMuliki <pre />
          Gelbachstraße 2 <pre />
          56410 Montabaur <pre />
          Telefon: 02602 94 73 756 <pre />
          Telefax: 02602 94 73 757 <pre />
          www.uropraxis-montabaur.de <pre />
          termin@uropraxis-montabaur.de
          <pre /> BSNR: 47 56 002 00
          <pre /> Zulassung KV Urkunde
        </p>
      </div>

      <div>
        <Footer />
      </div>
    </div>
  );
};

export default Impressum;
