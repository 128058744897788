import React from "react";
import "./Pages.css";

const Map = () => {
  return (
    <div className="map">
      <font style={{ color: "#00387b", fontSize: 30, fontWeight: "bold" }}>
        Ihre Anfahrt zu uns
      </font>{" "}
      <pre />
      <font style={{ color: "#00387b", fontSize: 30 }}>
        Es gibt einen eigenen Parkplatz, ca. 50 Meter von der Praxis entfernt
      </font>
      <div>
        <iframe
          title="Map"
          style={{
            width: 1000,
            height: 650,
            border: 0,
            paddingTop: 20,
            paddingBottom: 100,
            loading: "lazy",
            referrerpolicy: "no-referrer-when-downgrade",
          }}
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2541.3805018255885!2d7.830416577084486!3d50.43401287158903!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47bc2b6a6737854f%3A0x3efc17579b9d20f1!2sPraxis%20Urologie%20und%20Kinderurologie%20Nasser%20AlMuliki!5e0!3m2!1sen!2sde!4v1694169884066!5m2!1sen!2sde"
        ></iframe>
      </div>
    </div>
  );
};

export default Map;