import { useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import "./Pages.css";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import emailjs from "@emailjs/browser";

export default function ContactForm() {
  const key = "XvYmS-vyrxjWLt3cC";
  const serviceId = "service_1eed2ad";
  const praxisTeamTemplateId = "template_hnvhn7p";
  const almulikiTemplateId = "template_g5m56a4";
  const [validated, setValidated] = useState(false);
  const [receiver, setReceiver] = useState("choseValue");
  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    try {
      if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
      }
      setValidated(true);
      if (receiver === "almuliki") {
        await emailjs.sendForm(serviceId, almulikiTemplateId, form, key);
      } else {
        await emailjs.sendForm(serviceId, praxisTeamTemplateId, form, key);
      }
      document.getElementById("successText").innerHTML =
        "Dankeschön, wir haben Ihr Email erhalten und werden so schnell wie möglich Ihre Anfrage bearbeiten!";
    } catch (error) {
      console.log(error);
    } finally {
      setValidated(false);
    }
  };
  return (
    <div className="form">
      <Form renoValidate validated={validated} onSubmit={handleSubmit}>
        <Row className="mb-3">
          <Form.Group as={Col} sm="5" controlId="receiver">
            <Form.Label>Empfänger:</Form.Label>
            <Form.Control
              style={{ backgroundColor: "#edf1ff" }}
              as="select"
              value={receiver}
              onChange={(e) => {
                console.log("e.target.value", e.target.value);
                setReceiver(e.target.value);
              }}
            >
              <option selected value="choseValue">
                Empfänger auswählen
              </option>
              <option value="team">Praxis Team</option>
              <option value="almuliki">Dr.AlMuliki</option>
            </Form.Control>
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} sm="5" controlId="from_vorname">
            <Form.Label>Vorname:</Form.Label>
            <InputGroup hasValidation>
              <Form.Control
                style={{ backgroundColor: "#edf1ff", width: 20 }}
                type="text"
                name="from_vorname"
                placeholder="Vorname"
                required
              />
              <Form.Control.Feedback type="invalid">
                Vorname ist pflicht!.
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
          <Form.Group as={Col} sm="5" controlId="from_nachname">
            <Form.Label>Nachname:</Form.Label>
            <InputGroup hasValidation>
              <Form.Control
                style={{ backgroundColor: "#edf1ff" }}
                type="text"
                name="from_nachname"
                placeholder="Nachname"
                required
              />
              <Form.Control.Feedback type="invalid">
                Nachname ist pflicht!.
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} sm="5" controlId="email_from">
            <Form.Label>Email:</Form.Label>
            <InputGroup hasValidation>
              <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
              <Form.Control
                style={{ backgroundColor: "#edf1ff" }}
                type="text"
                name="email_from"
                placeholder="name@example.com"
                required
              />
              <Form.Control.Feedback type="invalid">
                Email ist pflicht oder ungültig!.
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
          <Form.Group as={Col} sm="5" controlId="telefone">
            <Form.Label>Telefonnummer:</Form.Label>
            <InputGroup hasValidation>
              <Form.Control
                style={{ backgroundColor: "#edf1ff" }}
                type="text"
                name="telefone"
                placeholder="Telefonnummer"
              />
            </InputGroup>
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} sm="10" controlId="message">
            <Form.Label>Nachricht:</Form.Label>
            <InputGroup hasValidation>
              <Form.Control
                style={{ backgroundColor: "#edf1ff" }}
                as="textarea"
                name="message"
                rows={3}
                required
              />
              <Form.Control.Feedback type="invalid">
                Nachricht ist pflicht!.
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
        </Row>
        <Form.Group as={Col} className="mb-3" controlId="datenschutz_accept">
          <Form.Check
            label="Ich stimme der Übermittlung und Verarbeitung meiner Nachricht gemäß Datenschutzbedingungen zu."
            feedback="Sie müssen die Datenschutzbedingungen akzeptieren!"
            feedbackType="invalid"
            required
          />
        </Form.Group>
        <Button
          style={{ backgroundColor: "#00387b" }}
          variant="primary"
          type="submit"
        >
          Absenden
        </Button>
        <h5 id="successText" className="successText">
          {" "}
        </h5>
      </Form>
    </div>
  );
}
